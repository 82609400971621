import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { completePayment, verifyDiscount } from '../../services';
import { images } from '../../assets/images';
import { Dialog } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from "@emotion/styled"

function Discount() {
    const [finalPrice, setFinalPrice] = useState(300.00); // Original price
    const [discount, setDiscount] = useState(0)
    const [error, setError] = useState('');
    const [initialValues, setInitialValues] = useState({
        discountCode: ''
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    
    // const initialOptions = {
    //     clientId: "AYz7LpsqS6_eNEA8Nay9IttfACdJiNAbHEzx1JNr9H_Pn5Js_3ko_IhfZDnH-CmUjjPtP6jfVxISJnms"
    // }

    const initialOptions = {
        clientId: "AS8z0f0omVEGYQZisWbvZN2fe9hXhGCe11dCThZzPYcx0KdhZeqNwGqwDrgFd_nWSviext1f-VFPb_IC"
    }

    const PaymentDialog = styled(Dialog)`
        .MuiPaper-root {
        background-color: #FCE816;
        padding: 2em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 40dvh;
    }
    `

    const handleSubmit = (values) => {
        verifyDiscount(values).then(response => {
            if (response.status === 200) {
                setError('')
            }
            setDiscount(finalPrice * 0.01 * response.data.discount)
            setFinalPrice(finalPrice - (finalPrice * 0.01 * response.data.discount))
        }).catch(err => {
            if (err.response.status === 400) {
                setError(err.response.data.message)
            }
            setError("Something went wrong")
        })
    }

    useEffect(() => {

    })

    return (
        <main className='checkout'>
            <header className="login__header">
                <a href="https://skinsagency.com">
                    <img src={images.logo} alt="logo" />
                </a>
            </header>
            <h1>Your Order</h1>
            <section>
                <article className='discount'>
                    <h2>Do you have a discount code?</h2>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <Field
                                name="discountCode"
                                placeholder="Enter Discount Code"
                                className="has-border-bottom"
                            />
                            <button className='btn yellow-cta' type='submit'>Apply Discount</button>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </Form>
                    </Formik>
                    <p>Final Price: ${finalPrice.toFixed(2)}</p>
                </article>
                <article>
                    <h2>Choose Payment Method</h2>
                    <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons 
                        createOrder={(data, actions) => {
                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            value: "210"
                                        }
                                    }
                                ]
                            })
                        }}
                        onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {
                                completePayment(1).then(response => {
                                    const data = response.data;
                                    setIsDialogOpen(true);
                                    console.log(data);
                                }).catch(err => {
                                    console.error(err);
                                })
                            });
                          }}/>
                    </PayPalScriptProvider>
            </article>
            </section>
            <PaymentDialog open={isDialogOpen}>
                <h2 style={{fontSize: "2.5em"}}>Payment Successful</h2>
                <div className="welcome-cta">
                        <Link to={"/flow/vision"}>
                            <span style={{color: 'black', fontSize: "2em"}}>
                                To your vision!
                                <svg xmlns="http://www.w3.org/2000/svg" width="198" height="198" viewBox="0 0 198 198">
                                    <g transform="translate(-489 -763)">
                                        <g transform="translate(489 763)" fill="none" stroke="#000000" strokeWidth="5">
                                            <circle cx="99" cy="99" r="99" stroke="none" />
                                            <circle cx="99" cy="99" r="96.5" fill="none" />
                                        </g>
                                        <g transform="translate(-47.5 929.216) rotate(-90)">
                                            <line y2="73.093" transform="translate(66.858 597.5)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="5" />
                                            <line x1="39.358" y2="39.358" transform="translate(66.858 634.984)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="5" />
                                            <line x2="39.358" y2="39.358" transform="translate(27.5 634.984)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="5" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </Link>
                    </div>
            </PaymentDialog>
        </main>
    )
}

export default Discount